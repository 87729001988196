import { BLogin } from '../../ui-components'
import { useNavigate } from 'react-router-dom'
import logoImage from '../../Assets/Images/kioskLogo.png'
import wordImage from '../../Assets/Images/kioskWord.png'
import { Loader, TextField, Icon, FieldGroupIconButton } from '@aws-amplify/ui-react'
import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { logout, login } from '../../Redux/userSlice'
import {
  signOut,
  signIn,
  confirmSignUp,
  resendSignUpCode,
  resetPassword,
  confirmResetPassword,
  fetchUserAttributes
} from 'aws-amplify/auth'

const LoginScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const timerRef = useRef(null);
  const [password, setPassword] = useState('');
  const [username, setUserName] = useState('');
  const [error, setError] = useState('');
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [resetPasswordUsername, setResetPasswordUsername] = useState('');
  const [confirmCode, setConfirmCode] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [canLogin, setCanLogin] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidUsername, setIsValidUsername] = useState(true);
  const [isResetPasswordConfirmed, setResetPasswordConfirmed] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmedNewPassword, setShowConfirmNewPassword] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showResetErrorDialog, setShowResetErrorDialog] = useState(false);
  const [showResetSuccessDialog, setShowResetSuccessDialog] = useState(false);
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);
  const [showVerifyAccountDialog, setShowVerifyAccountDialog] = useState(false);
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [countdown, setCountdown] = useState(60);

  const handleErrorDialog = () => setShowErrorDialog(!showErrorDialog);

  const handleResetErrorDialog = () => setShowResetErrorDialog(false);

  const handleResetSuccessDialog = () => setShowResetSuccessDialog(!showResetSuccessDialog);

  const handlePasswordInput = (event) => {
    setPassword(event.target.value);
    const passwordRegex = /^[a-zA-Z0-9-!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,20}$/;
    const isValidPassword = passwordRegex.test(event.target.value);
    setIsValidPassword(isValidPassword);
  }

  const handleUserNameInput = (event) => {
    setUserName(event.target.value);
    const usernameRegex = /^[a-zA-Z0-9]{4,20}$/;
    const isValidUsername = usernameRegex.test(event.target.value);
    setIsValidUsername(isValidUsername);
  }

  const handleConfirmationCodeInput = (event) => {
    setConfirmCode(event.target.value)
  };

  const handeResetPasswordDialog = () => {
    setShowResetPasswordDialog(!showResetPasswordDialog);
    setResetPasswordConfirmed(false);
    setResetPasswordUsername('');
  }

  const handleResetPassswordUsername = (event) => {
    setResetPasswordUsername(event.target.value);
  }

  const handleConfirmationCode = (event) => {
    setCode(event.target.value);
  }

  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
    setPasswordsMatch(event.target.value === confirmNewPassword)
  }

  const handleConfirmNewPassword = (event) => {
    setConfirmNewPassword(event.target.value);
    setPasswordsMatch(event.target.value === newPassword)
  }

  const handleLogin = async () => {
    try {
      await signIn({ username, password });
      const profile = (await fetchUserAttributes()).profile;
      dispatch(login({ username, profile }));
      navigate('/Product')
    } catch (error) {
      console.error('error signing in', error);
      if (error.name === 'UserNotConfirmedException' || error.name === 'UserUnAuthenticatedException') {
        setError(error.message);
        setShowVerifyAccountDialog(true);
        await resendVerficationCode();
      }
      else {
        setError('Incorrect username or password');
        setShowErrorDialog(true);
      }
    }
  }

  const forgetPassword = async () => {
    try {
      await resetPassword({ username: resetPasswordUsername });
      setResetPasswordConfirmed(true);
    } catch (error) {
      console.log(error);
      let customErrorMessage = '';
      if (error.name === 'UserNotFoundException')
        customErrorMessage = `Cannot reset password for the user as there is no registered. Please sign up the account first.`;
      else if (error.name === 'InvalidParameterException')
        customErrorMessage = 'Cannot reset password for the user as there is registered but not verified usename. Please login the account to finish the verification.';
      else
        customErrorMessage = error.message;

      setError(customErrorMessage);
      setShowResetPasswordDialog(false);
      setShowResetErrorDialog(true);
    }
  }

  const forgotPasswordSubmit = async () => {
    try {
      setShowLoader(true);
      await confirmResetPassword({
        username: resetPasswordUsername,
        confirmationCode: code,
        newPassword: newPassword
      });
      handeResetPasswordDialog();
      handleResetSuccessDialog();
    } catch (error) {
      console.log(error);
      setError(error.message);
      setShowResetErrorDialog(true);
    } finally {
      setShowLoader(false);
    }
  }

  const handleConfirmSignUp = async () => {
    try {
      await confirmSignUp({
        username: username,
        confirmationCode: confirmCode
      });
      dispatch(login({ username, profile: 'Full' }));
      navigate('/Product');
    } catch (error) {
      console.log('error', error);
      setError(error.message);
      setShowErrorDialog(true);
    }
  }

  const resendVerficationCode = async () => {
    try {
      const { destination } = await resendSignUpCode({ username: username });
      setEmail(destination);
    }
    catch (error) {
      resetTimer();
      console.log(error);
      setError(error.message);
      setShowErrorDialog(true);
    }
  }

  const emailNotVerifiedResendCode = async () => {
    if (timerRef.current) return;
    setIsCountdownActive(true);
    timerRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          resetTimer();
          return 60;
        }
        return prev - 1;
      });
    }, 1000);

    await resendVerficationCode();
  }

  const exit = async () => {
    try {
      await signOut();
      console.log('Sign out succeed');
    } catch (error) {
      console.error('error signing out: ', error);
    }
    dispatch(logout());
    navigate('/')
  }

  const resendResetPasswordCode = async () => {
    if (timerRef.current) return;
    setIsCountdownActive(true);
    timerRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          resetTimer();
          return 60;
        }
        return prev - 1;
      });
    }, 1000);

    try {
      await forgetPassword();
    } catch (error) {
      console.log("Error resending reset password code:", error);
      resetTimer();
    }
  }

  const resetTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = null;
    setIsCountdownActive(false);
    setCountdown(60);
  };

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  const chat = () => {
    navigate('/Chat')
  }

  useEffect(() => {
    setCanLogin(password && username && isValidUsername && isValidPassword);
  }, [password, username, isValidUsername, isValidPassword])

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, []);

  return (
    <div>
      {showResetPasswordDialog && (
        <div className="dialog">
          <div className="dialog-content">
            <button className="close-icon" style={{ color: '#000000' }} onClick={handeResetPasswordDialog}>&#10005;</button>
            <h2>Reset Password</h2>
            <div>
              <input className='amplify-input' placeholder='Enter username' onChange={handleResetPassswordUsername} />
              <button className='amplify-button' style={{ margin: 20 }} onClick={forgetPassword} disabled={resetPasswordUsername.length < 1}>Confirm</button>
            </div>
          </div>
        </div>
      )}
      {isResetPasswordConfirmed && (
        <div className="dialog">
          <div className="dialog-content">
            <h2>We Emailed You</h2>
            <p className='popup-bodyText'>Enter the verification code,<br/> it may take a minute to arrive.</p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <TextField placeholder='Enter Verification Code' onChange={handleConfirmationCode} />
              <TextField
                placeholder='Enter New Password'
                onChange={handleNewPassword}
                type={showNewPassword ? 'text' : 'password'}
                innerEndComponent={
                  <FieldGroupIconButton
                    ariaLabel='NewPasswordMaskToggle'
                    variation='link'
                    onClick={() => setShowNewPassword(!showNewPassword)}>
                    {
                      showNewPassword ?
                        <Icon
                          ariaLabel='Show new password'
                          viewBox={{ minX: 0, minY: 0, width: 16, height: 16 }}>
                          <path fillRule={'evenodd'} clipRule={"evenodd"} d="M16 16H13L10.8368 13.3376C9.96488 13.7682 8.99592 14 8 14C6.09909 14 4.29638 13.1557 3.07945 11.6953L0 8L3.07945 4.30466C3.14989 4.22013 3.22229 4.13767 3.29656 4.05731L0 0H3L16 16ZM5.35254 6.58774C5.12755 7.00862 5 7.48941 5 8C5 9.65685 6.34315 11 8 11C8.29178 11 8.57383 10.9583 8.84053 10.8807L5.35254 6.58774Z" />
                          <path d="M16 8L14.2278 10.1266L7.63351 2.01048C7.75518 2.00351 7.87739 2 8 2C9.90091 2 11.7036 2.84434 12.9206 4.30466L16 8Z" />
                        </Icon>
                        :
                        <Icon
                          ariaLabel='Hide new password'
                          viewBox={{ minX: 0, minY: 0, width: 16, height: 16 }}>
                          <path fillRule={'evenodd'} clipRule={"evenodd"} d="M0 8L3.07945 4.30466C4.29638 2.84434 6.09909 2 8 2C9.90091 2 11.7036 2.84434 12.9206 4.30466L16 8L12.9206 11.6953C11.7036 13.1557 9.90091 14 8 14C6.09909 14 4.29638 13.1557 3.07945 11.6953L0 8ZM8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z" />
                        </Icon>
                    }
                  </FieldGroupIconButton>
                } />
              <TextField
                placeholder='Confirm Password'
                onChange={handleConfirmNewPassword}
                type={showConfirmedNewPassword ? 'text' : 'password'}
                innerEndComponent={
                  <FieldGroupIconButton
                    ariaLabel='NewPasswordMaskToggle'
                    variation='link'
                    onClick={() => setShowConfirmNewPassword(!showConfirmedNewPassword)}>
                    {
                      showConfirmedNewPassword ?
                        <Icon
                          ariaLabel='Show confirmed password'
                          viewBox={{ minX: 0, minY: 0, width: 16, height: 16 }}>
                          <path fillRule={'evenodd'} clipRule={"evenodd"} d="M16 16H13L10.8368 13.3376C9.96488 13.7682 8.99592 14 8 14C6.09909 14 4.29638 13.1557 3.07945 11.6953L0 8L3.07945 4.30466C3.14989 4.22013 3.22229 4.13767 3.29656 4.05731L0 0H3L16 16ZM5.35254 6.58774C5.12755 7.00862 5 7.48941 5 8C5 9.65685 6.34315 11 8 11C8.29178 11 8.57383 10.9583 8.84053 10.8807L5.35254 6.58774Z" />
                          <path d="M16 8L14.2278 10.1266L7.63351 2.01048C7.75518 2.00351 7.87739 2 8 2C9.90091 2 11.7036 2.84434 12.9206 4.30466L16 8Z" />
                        </Icon>
                        :
                        <Icon
                          ariaLabel='Hide confirmed password'
                          viewBox={{ minX: 0, minY: 0, width: 16, height: 16 }}>
                          <path fillRule={'evenodd'} clipRule={"evenodd"} d="M0 8L3.07945 4.30466C4.29638 2.84434 6.09909 2 8 2C9.90091 2 11.7036 2.84434 12.9206 4.30466L16 8L12.9206 11.6953C11.7036 13.1557 9.90091 14 8 14C6.09909 14 4.29638 13.1557 3.07945 11.6953L0 8ZM8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z" />
                        </Icon>
                    }
                  </FieldGroupIconButton>
                } />
            </div>
            <p style={{color: 'red', visibility: code.length === 0 ? 'visible' : 'hidden'}}>
              Verification code cannot be empty
            </p>
            <p style={{color: 'red', visibility: newPassword.length >= 8 && newPassword.length <= 20 ? 'hidden' : 'visible'}}>
              New Password length must be between 8 to 20
            </p>
            <p style={{color: 'red', visibility: passwordsMatch || newPassword.length === 0 || confirmNewPassword.length === 0 ? 'hidden' : 'visible'}}>
              Password does not match, please enter a matching password
            </p>
            <p style={{color: 'red', visibility: isCountdownActive ? 'visible' : 'hidden'}}>
              The verification code has been sent, please check your emailbox
            </p>
            <button className='amplify-button' style={{ margin: 10 }} onClick={forgotPasswordSubmit} disabled={code.length < 1 || !passwordsMatch || newPassword.length < 8 || newPassword.length > 20}>Submit</button>
            <button className='amplify-button' style={{ margin: 10 }} onClick={resendResetPasswordCode} disabled={showLoader || isCountdownActive}>
              Resend code
            </button>
            {isCountdownActive && (
              <div style={{ marginBottom: "10px" }}>
                <Loader size="large" />
                <p>{countdown} seconds remaining</p>
              </div>
            )}
          </div>
        </div>
      )}
      {showResetSuccessDialog && (
        <div className="dialog">
          <div className="dialog-content">
            <h2>Reset Password Successful</h2>
            <p className='popup-bodyText'>You have reset password successfully!</p>
            <button className='amplify-button' style={{ margin: 20 }} onClick={handleResetSuccessDialog}>OK</button>
          </div>
        </div>
      )}
      {showVerifyAccountDialog && (
        <div className="dialog">
          <div className="dialog-content">
            <h2>Your email not verified</h2>
            <p className='popup-bodyText'>To log in, enter the verification code we emailed to {email},<br /> it may take a minute to arrive.</p>
            <input className='amplify-input' placeholder='Enter your code here' onChange={handleConfirmationCodeInput} />
            <p style={{color: 'red', visibility: confirmCode.length === 0 ? 'visible' : 'hidden'}}>
              Verification code cannot be empty
            </p>
            <p style={{color: 'red', visibility: isCountdownActive ? 'visible' : 'hidden'}}>
              The verification code has been sent, please check your emailbox
            </p>
            <div style={{ margin: 20 }}>
              <button className='amplify-button' style={{ position: 'relative' }} onClick={handleConfirmSignUp} disabled={confirmCode.length < 1}>Confirm</button>
              <button className='amplify-button' style={{ position: 'relative', left: 30 }} onClick={emailNotVerifiedResendCode} disabled={isCountdownActive}>
                Resend Code
              </button>
              {isCountdownActive && (
                <div style={{ marginBottom: "10px" }}>
                  <Loader size="large" />
                  <p>{countdown} seconds remaining</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {showErrorDialog && (
        <div className="dialog">
          <div className="dialog-content">
            <h2>Login Failed</h2>
            <p className='popup-bodyText'>{error}</p>
            <button className='amplify-button' style={{ margin: 20 }} onClick={handleErrorDialog}>OK</button>
          </div>
        </div>
      )}
      {showResetErrorDialog && (
        <div className="dialog">
          <div className="dialog-content">
            <h2>Reset Failed</h2>
            <p className='popup-bodyText' style={{ width: 450 }}>{error}</p>
            <button className='amplify-button' style={{ margin: 20 }} onClick={handleResetErrorDialog}>OK</button>
          </div>
        </div>
      )}
      <BLogin className='BLogin'
        overrides={{
          "Logo": { src: logoImage },
          "Word": { src: wordImage },
          "Help": { onClick: () => openInNewTab('https://vcc-beta.vsd-aws.compunetix.com/KIS/operators/en/Kiosk') },
          "Exit": { onClick: () => exit() },
          "Password": { onChange: (event) => handlePasswordInput(event), hasError: !isValidPassword, errorMessage: 'Password length must be at least 8 to 20' },
          "Username": { onChange: (event) => handleUserNameInput(event), hasError: !isValidUsername, errorMessage: 'Username length must be at least 4 to 20' },
          "FAQ": { isDisabled: true, onClick: () => chat() },
          "Login": { isDisabled: !canLogin, onClick: () => handleLogin() },
          "ResetPassword": { onClick: () => handeResetPasswordDialog() }
        }} />
    </div>
  )
}

export default LoginScreen;