import { BSignUp } from '../../ui-components'
import { useNavigate } from 'react-router-dom'
import { Loader } from '@aws-amplify/ui-react'
import logoImage from '../../Assets/Images/kioskLogo.png'
import wordImage from '../../Assets/Images/kioskWord.png'
import { signIn, signUp, confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';
import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { login } from '../../Redux/userSlice'
import { isPossiblePhoneNumber } from 'react-phone-number-input'

const SignUpScreen = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [emailMatch, setEmailMatch] = useState(true);
  const [canSignUp, setCanSignUp] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidUsername, setIsValidUsername] = useState(true);
  const [goodPhoneNumber, setGoodPhoneNumber] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showUserExistDialog, setShowUserExistDialog] = useState(false);
  const [error, setError] = useState('');
  const [confirmCode, setConfirmCode] = useState('');
  const timerRef = useRef(null);
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [badWords, setBadWords] = useState([]);
  const [badWordsWarning, setBadWordsWarning] = useState('');

  const dispatch = useDispatch();

  const handleUserExistDialog = () => {
    setShowUserExistDialog(false);
  }

  const handleErrorDialog = () => setShowErrorDialog(!showErrorDialog);

  const handleUsernameInput = (event) => {
    const newUsername = event.target.value;
    setUsername(newUsername);
    const hasBadWords = badWords.some(badword => newUsername.includes(badword));
    if (hasBadWords) {
      setIsValidUsername(false);
      setBadWordsWarning('Username cannot have obscene words');
    } else {
      const usernameRegex = /^[a-zA-Z0-9]{4,20}$/;
      const isValid = usernameRegex.test(newUsername);
      setIsValidUsername(isValid);
      setBadWordsWarning(isValid ? '' : 'Username length must be at least 4 to 20 characters');
    }
  };

  const handleEmailInput = (event) => {
    setEmail(event.target.value);
    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(event.target.value);
    setIsValidEmail(isValidEmail);
  };

  const handlePasswordInput = (event) => {
    setPassword(event.target.value);
    const passwordRegex = /^[a-zA-Z0-9-!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,20}$/;
    const isValidPassword = passwordRegex.test(event.target.value);
    setIsValidPassword(isValidPassword);
  };

  const handlePhoneNumberInput = (value) => {
    const isPosssible = value && isPossiblePhoneNumber(value) ? true : false;
    setPhoneNumber(value === undefined ? '' : value);
    setGoodPhoneNumber(isPosssible);
  }

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
    setPasswordMatch(event.target.value === password)
  }

  const handleConfirmEmail = (event) => {
    setConfirmEmail(event.target.value)
    setEmailMatch(event.target.value === email)
  }

  const handleConfirmationCodeInput = (event) => {
    setConfirmCode(event.target.value)
  };

  const resetTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = null;
    setIsCountdownActive(false);
    setCountdown(60);
  };

  useEffect(() => {
    const validateSignUp = () => {
      return (
        email.length > 0
        && username.length > 0
        && password.length > 0
        && confirmPassword.length > 0
        && confirmEmail.length > 0
        && phoneNumber.length > 0
        && isValidEmail
        && isValidUsername
        && isValidPassword
        && passwordMatch
        && emailMatch
        && goodPhoneNumber
      );
    }
    setCanSignUp(validateSignUp());
    document.querySelector('.PhoneInputInput').style.width = "620px";
  }, [email, username, password, confirmPassword, confirmEmail, phoneNumber, goodPhoneNumber, isValidEmail, isValidUsername, isValidPassword, passwordMatch, emailMatch]);

  useEffect(() => {
    fetch(require('../../Assets/BadWord/google-full.txt')) 
    .then(response => response.text())
    .then(data => {
      const badWordsArray = data.split(/\r?\n/).map(word => word.trim().toLowerCase());
      setBadWords(badWordsArray);
    })
    .catch(error => {
      console.error('Error fetching the text file:', error);
    });

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, []);

  const navigate = useNavigate()
  const handleSignUp = async () => {
    try {
      const { userId } = await signUp({
        username,
        password,
        options: {
          userAttributes: {
            email: email,
            phone_number: phoneNumber, // E.164 number convention
            profile: 'Full'
          },
        },
        autoSignIn: true
      });
      console.log(userId);
      setShowDialog(true);
    } catch (error) {
      console.log('error signing up:', error);
      if (error.name === 'UsernameExistsException') {
        setError(error.message);
        setShowUserExistDialog(true);
      } else {
        setError(error.message);
        setShowErrorDialog(true);
      }
    }
  }

  const handleConfirmationSignUp = async () => {
    try {
      const { isSignUpComplete } = await confirmSignUp({
        username: username,
        confirmationCode: confirmCode
      });
      if (isSignUpComplete)
        await signIn({ username, password });
      dispatch(login({ username, profile: 'Full' }));
      navigate('/Product');
    } catch (error) {
      console.log('error', error);
      setError(error.message);
      setShowErrorDialog(true);
    }
  }

  const resendVerficationCode = async () => {
    if (timerRef.current) return;
    setIsCountdownActive(true);
    timerRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          resetTimer();
          return 60;
        }
        return prev - 1;
      });
    }, 1000);

    try {
      await resendSignUpCode({ username: username });
    }
    catch (error) {
      console.log(error);
      resetTimer();
    }
  }

  const exit = () => {
    navigate('/')
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  const maskedEmail = (email) => {
    const [localPart, domain] = email.split("@");
    const maskedLocal = localPart[0];
    const maskedDomain = domain[0];
    return `${maskedLocal}***@${maskedDomain}***`; 
  }; 

  return (
    <div>
      {showDialog && (
        <div className="dialog">
          <div className="dialog-content">
            <h2>We Emailed You</h2>
            <p className='popup-bodyText'>To log in, enter the code we emailed to {maskedEmail(email)},<br /> it may take a minute to arrive.</p>
            <input className='amplify-input' placeholder='Enter your code here' onChange={handleConfirmationCodeInput} />
            <p style={{color: 'red', visibility: confirmCode.length === 0 ? 'visible' : 'hidden'}}>
              Verification code cannot be empty
            </p>
            <p style={{color: 'red', visibility: isCountdownActive ? 'visible' : 'hidden'}}>
              The verification code has been sent, please check your emailbox
            </p>
            <div style={{ margin: 20 }}>
              <button className='amplify-button' style={{ margin: 10 }} onClick={handleConfirmationSignUp} disabled={confirmCode.length < 1}>Confirm</button>
              <button className='amplify-button' style={{ margin: 10 }} onClick={resendVerficationCode} disabled={isCountdownActive}>
                Resend Code
              </button>
              {isCountdownActive && (
                <div style={{ marginBottom: "10px" }}>
                  <Loader size="large" />
                  <p>{countdown} seconds remaining</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {showErrorDialog && (
        <div className="dialog">
          <div className="dialog-content">
            <h2>Sign up failed</h2>
            <p className='popup-bodyText'>{error}</p>
            <button className='amplify-button' style={{ margin: 20 }} onClick={handleErrorDialog}>OK</button>
          </div>
        </div>
      )}
      {showUserExistDialog && (
        <div className="dialog">
          <div className="dialog-content">
            <h2>Sign Up Failed!</h2>
            <p className='popup-bodyText'>User already exist or email has not been verified,</p>
            <p className='popup-bodyText'>Please go to the login page to finish verification or login process.</p>
            <button className='amplify-button' style={{ margin: 20 }} onClick={handleUserExistDialog}>No, not yet</button>
            <button className='amplify-button' style={{ margin: 20 }} onClick={exit}>Yes, let's go</button>
          </div>
        </div>
      )}
      <BSignUp className='BSignUp'
        overrides={{
          "Logo": { src: logoImage },
          "Word": { src: wordImage },
          "Username": { onChange: (event) => handleUsernameInput(event), hasError: !isValidUsername, errorMessage: badWordsWarning },
          "Password": { onChange: (event) => handlePasswordInput(event), hasError: !isValidPassword, errorMessage: 'Password length must be at least 8 to 20' },
          "EmailAddress": { onChange: (event) => handleEmailInput(event), hasError: !isValidEmail, errorMessage: 'Email must be a right format,ex: example@example.com' },
          "EnterPasswordAgain": { onChange: (event) => handleConfirmPassword(event), hasError: !passwordMatch, errorMessage: 'Password does not match, Please enter a matching password' },
          "EnterEmailAgain": { onChange: (event) => handleConfirmEmail(event), hasError: !emailMatch, errorMessage: 'Email does not match, Please enter a matching email' },
          "PhoneNumber": { onChange: (event) => handlePhoneNumberInput(event) },
          "PhoneNumberNotValidText": { display: (goodPhoneNumber ? 'none' : 'block') },
          "Help": { onClick: () => openInNewTab('https://vcc-beta.vsd-aws.compunetix.com/KIS/operators/en/Kiosk') },
          "Login": { isDisabled: !canSignUp, onClick: () => handleSignUp() },
          "Exit": { onClick: () => exit() },
        }} />
    </div>
  )
}

export default SignUpScreen;