/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { useState } from "react";
import { getOverrideProps } from "./utils";
import {
  Button,
  Icon,
  Flex,
  Image,
  Text,
  TextField,
  View,
  FieldGroupIconButton
} from "@aws-amplify/ui-react";
export default function BLogin(props) {
  const { overrides, ...rest } = props;
  const [showPassword, setShowPassword] = useState(false);
  return (
    <View
      width="744px"
      height="1400px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(44,75,98,1)"
      {...getOverrideProps(overrides, "BLogin")}
      {...rest}
    >
      <Flex
        gap="35px"
        direction="column"
        width="680px"
        height="583px"
        justifyContent="flex-start"
        alignItems="center"
        position="absolute"
        top="calc(50% - 291.5px - 180.5px)"
        left="4.3%"
        right="4.3%"
        borderRadius="35px"
        padding="10px 10px 10px 10px"
        {...getOverrideProps(overrides, "Contents")}
      >
        <Text
          fontFamily="Urbanist"
          fontSize="46px"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="55.20000076293945px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Login"
          {...getOverrideProps(overrides, "Title")}
        ></Text>
        <Text
          fontFamily="Urbanist"
          fontSize="30px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="36px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Enter username and password"
          {...getOverrideProps(overrides, "Subtitle1614301")}
        ></Text>
        <View
          width="unset"
          height="128px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Inputs")}
        >
          <TextField
            width="660px"
            height="54px"
            placeholder="Username"
            position="absolute"
            borderRadius="35px"
            top="calc(50% - 27px - 38px)"
            left="0%"
            right="0%"
            size="Default"
            {...getOverrideProps(overrides, "Username")}
          ></TextField>
          <TextField
            type={showPassword ? "text" : "password"}
            width="660px"
            height="54px"
            placeholder="Password"
            position="absolute"
            borderRadius="35px"
            top="calc(50% - 27px - -37px)"
            left="0px"
            size="Default"
            innerEndComponent={
              <FieldGroupIconButton
                ariaLabel="MaskToggle"
                variation="link"
                onClick={()=> setShowPassword(!showPassword)}>
                {
                  showPassword ?
                    <Icon
                      ariaLabel="Show password"
                      viewBox={{ minX: 0, minY: 0, width: 16, height: 16 }}>
                      <path fillRule={'evenodd'} clipRule={"evenodd"} d="M16 16H13L10.8368 13.3376C9.96488 13.7682 8.99592 14 8 14C6.09909 14 4.29638 13.1557 3.07945 11.6953L0 8L3.07945 4.30466C3.14989 4.22013 3.22229 4.13767 3.29656 4.05731L0 0H3L16 16ZM5.35254 6.58774C5.12755 7.00862 5 7.48941 5 8C5 9.65685 6.34315 11 8 11C8.29178 11 8.57383 10.9583 8.84053 10.8807L5.35254 6.58774Z" />
                      <path d="M16 8L14.2278 10.1266L7.63351 2.01048C7.75518 2.00351 7.87739 2 8 2C9.90091 2 11.7036 2.84434 12.9206 4.30466L16 8Z" />
                    </Icon>
                    : 
                    <Icon
                      ariaLabel={"Hide password"}
                      viewBox={{ minX: 0, minY: 0, width: 16, height: 16 }}>
                      <path fillRule={'evenodd'} clipRule={"evenodd"} d="M0 8L3.07945 4.30466C4.29638 2.84434 6.09909 2 8 2C9.90091 2 11.7036 2.84434 12.9206 4.30466L16 8L12.9206 11.6953C11.7036 13.1557 9.90091 14 8 14C6.09909 14 4.29638 13.1557 3.07945 11.6953L0 8ZM8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z" />
                    </Icon>
                }
              </FieldGroupIconButton>
            }
            {...getOverrideProps(overrides, "Password")}
          ></TextField>
        </View>
        <Button
          width="317px"
          height="75px"
          shrink="0"
          buttontype="Default"
          isDisabled={false}
          children="Login"
          {...getOverrideProps(overrides, "Login")}
        ></Button>
        <Text
          fontFamily="Urbanist"
          fontSize="24px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="28.80000114440918px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Forgot your password? Request a new one!"
          {...getOverrideProps(overrides, "Subtitle1614306")}
        ></Text>
        <Button
          width="317px"
          height="75px"
          shrink="0"
          buttontype="Default"
          isDisabled={false}
          children="Reset Password"
          {...getOverrideProps(overrides, "ResetPassword")}
        ></Button>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="absolute"
        top="30px"
        right="36px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "HeaderButtonGroup")}
      >
        <Button
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={true}
          children="FAQ"
          {...getOverrideProps(overrides, "FAQ")}
        ></Button>
        <Button
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={true}
          children="Help"
          {...getOverrideProps(overrides, "Help")}
        ></Button>
        <Button
          justifyContent="flex-end"
          alignItems="flex-start"
          shrink="0"
          buttontype="HeaderButton"
          isDisabled={false}
          children="Exit"
          {...getOverrideProps(overrides, "Exit")}
        ></Button>
      </Flex>
      <Image
        width="85px"
        height="86px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="39px"
        left="32px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        {...getOverrideProps(overrides, "Logo")}
      ></Image>
    </View>
  );
}
