import React, { useState } from "react";
import Joi from "joi-browser";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom'
import { useSelector} from 'react-redux'
import { useDispatch } from 'react-redux'
import { leaveNewPost } from "../../Redux/userSlice";
import { PostCommentApi } from "../../data/Api";

const NewPost = () => {
  const [data, setData] = useState({ description: "" });
  const [errors, setErrors] = useState({ description: "" });
  const [isPostSuccessful, setIsPostSuccessful] = useState(false);
  const [isPostFailed, setIsPostFailed] = useState(false);
  const username = useSelector(state => state.user.username);
  const newQuestionId = useSelector(state => state.user.newQuestionId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schema = {
    description: Joi.string().required().min(5).max(100).label("Description"),
  };

  const handleChange = ({ currentTarget: input }) => {
    const { name, value } = input;
    setData({ ...data, [name]: value });
    const errorMessage = validateProperty(input);
    setErrors({ ...errors, [input.name]: errorMessage });
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schemaObj = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schemaObj);
    return error ? error.details[0].message : null;
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    const comment = ({
      Type: 0,
      UserId: username,
      QuestionId: newQuestionId,
      Content: data.description,
      Timestamp: Date.now(),
    })

    const { success } = await PostCommentApi(comment);
    if (success) 
      setIsPostSuccessful(true);
    else
      setIsPostFailed(false);

  };

  const handlePostSuccessDialog = () => {
    setIsPostSuccessful(false);
    dispatch(leaveNewPost());
    navigate('/Chat');
  }

  const handlePostFailedDialog = () => {
    setIsPostFailed(false);
  }

  const handleLeaveNewPost = () => {
    dispatch(leaveNewPost())
  }

  return (
    <div>
      <ToastContainer />
      <div className="container">
        <div style={{position: 'absolute',right:100}}>
          <Link to="/Chat">
              <button type="button" className="chatBackButton" onClick={handleLeaveNewPost}>
                  Back
              </button>
          </Link>
        </div>
        <h1 className="text-center m-2" style={{color:"#F1F1F1",fontWeight:1000,margin:(0,35,0,35)}}>Create a New Question</h1>
        <div className="container col-lg-6 shadow-lg p-3 mt-5 bg-body rounded">
          <form onSubmit={handleSubmit}>
            <div className="form-group" style={{ backgroundColor: "#F1F1F1", borderRadius:10,width:650 }}>
              <label htmlFor="description" style={{position:'relative',fontSize:30,fontWeight:1000}}>Question description:</label>
              <br/>
              <textarea
                value={data.description}
                onChange={handleChange}
                name="description"
                type="description"
                id="description"
                className="form-control"
                style={{height: 300,width:450,fontSize:30}}
              />
              <div style={{height:50}}>
                {errors.description && (
                  <div className="alert-info" style={{color:'red',fontSize:20,fontWeight:"bold"}}>
                    {errors.description}
                  </div>
                )}
              </div>
              <div className="text-center">
                <button
                  className="chatBlackButton"
                  disabled={validate()}
                  style={{margin:(0,20,0,20)}}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {isPostSuccessful && (
        <div className="dialog">
          <div className="dialog-content">
            <h2>Create Question Succeed!</h2>
            <p className='popup-bodyText'>Navigate to All Questions.</p>
            <button className='amplify-button' style={{margin: 20}} onClick={handlePostSuccessDialog}>OK</button>            
          </div>
        </div>
      )}
      {isPostFailed && (
        <div className="dialog">
          <div className="dialog-content">
            <h2>Create Question Failed!</h2>
            <p className='popup-bodyText'>Something went wrong. Please wait to try again</p>
            <button className='amplify-button' style={{margin: 20}} onClick={handlePostFailedDialog}>OK</button>            
          </div>
        </div>
      )}
    </div>
  );
};

export default NewPost;