import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        username: null,
        isLoggedIn: false,
        profile: null,
        newQuestionId: null
    },
    reducers: {
        login: (state, action) => {
            state.username = action.payload.username;
            state.profile = action.payload.profile;
            state.isLoggedIn = true;
        },
        logout: (state, action) => {
            state.username = null;
            state.profile = null;
            state.isLoggedIn = false;
        },
        createNewPost:(state, action) => {
            state.newQuestionId = action.payload.newQuestionId;
        },
        leaveNewPost:(state, action) => {
            state.newQuestionId = null;
        }
    }
})

export const {login, logout, createNewPost, leaveNewPost} = userSlice.actions

export default userSlice.reducer