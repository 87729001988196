import './App.css';
import LandScapeProductsScreen from './Screens/LandScapeScreens/ProductsScreen'
import LandScapeSignUpScreen from './Screens/LandScapeScreens/SignUpScreen'
import LandScapeWelcomeScreen from './Screens/LandScapeScreens/WelcomeScreen'
import LandScapeHIMScreen from './Screens/LandScapeScreens/HIMScreen'
import LandScapeDocScreen from './Screens/LandScapeScreens/DocScreen'
import LandscapeDashBoard from './Screens/LandScapeScreens/LandscapeDashBoard';
import LandScapeLoginScreen from './Screens/LandScapeScreens/LoginScreen'

import PortraitWelcomeScreen from './Screens/PortraitScreens/WelcomeScreen'
import PortraitLoginScreen from './Screens/PortraitScreens/LoginScreen'
import PortraitSignUpScreen from './Screens/PortraitScreens/SignUpScreen'
import PortraitProductsScreen from './Screens/PortraitScreens/ProductsScreen'
import PortraitHIMScreen from './Screens/PortraitScreens/HIMScreen'
import PortraitDocScreen from './Screens/PortraitScreens/DocScreen'
import PostDetail from './Screens/LandScapeScreens/PostDetail';
import NewPost from './Screens/LandScapeScreens/CreatePost';
import { Route, Routes, Navigate } from 'react-router-dom';
import ProtectedRoute from './Router/ProtectedRoute';
function App() {
  const orientation = window.innerWidth > window.innerHeight ? true : false;

  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={orientation ? <LandScapeWelcomeScreen /> : <PortraitWelcomeScreen />} />
        <Route path='/SignUp' element={orientation ? <LandScapeSignUpScreen /> : <PortraitSignUpScreen />} />
        <Route path='/Product' element={
          <ProtectedRoute>
            {orientation ? <LandScapeProductsScreen /> : <PortraitProductsScreen />}
          </ProtectedRoute>
        } />
        <Route path='/Him' element={
          <ProtectedRoute>
            {orientation ? <LandScapeHIMScreen /> : <PortraitHIMScreen />}
          </ProtectedRoute>
        } />
        <Route path='/Doc' element={
          <ProtectedRoute>
            {orientation ? <LandScapeDocScreen /> : <PortraitDocScreen />}
          </ProtectedRoute>
        } />      
        <Route path='/Chat' element={
          <ProtectedRoute>
            <LandscapeDashBoard />
          </ProtectedRoute>
        } />
        <Route path='/post/:QuestionId/:UserId' element={
          <ProtectedRoute>
            <PostDetail />
          </ProtectedRoute>
        } />
        <Route path='/NewPost' element={
          <ProtectedRoute>
            <NewPost />
          </ProtectedRoute>
        } />
        
        <Route path='/Login' element={orientation ? <LandScapeLoginScreen /> : <PortraitLoginScreen />} />
      
      {/*Unmatched paths*/}
        <Route path='*' element={<Navigate to="/Login" />} />
      </Routes>
    </div>
  );
}

export default App;
