import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'
  import { configureStore } from '@reduxjs/toolkit'
  import storage from 'redux-persist/lib/storage'
  import userReducer from './userSlice'
  
  const persistConfig = {
    key: 'user',
    storage,
    whitelist: ['isLoggedIn', 'username', 'profile', 'newQuestionId'],
  };
  
  const persistedUserReducer = persistReducer(persistConfig, userReducer);
  
  const store = configureStore({
    reducer: {
      user: persistedUserReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });
  
  const persistor = persistStore(store);
  
  export { store, persistor };